import axios from 'axios';

const get = (url, params, config) => {
  return new Promise((resolve, reject) => {
    axios.get(url, Object.assign({}, { params }, config))
      .then((response) => {
        resolve(response && response.data);
      }).catch((error) => {
        reject(error && error.response);
      });
  });
};

const post = (url, data, config) => {
  return new Promise((resolve, reject) => {
    axios.post(url, data, config)
      .then((response) => {
        resolve(response && response.data);
      }).catch((error) => {
        reject(error && error.response);
      });
  });
};

const put = (url, data) => {
  return new Promise((resolve, reject) => {
    axios.put(url, data)
      .then((response) => {
        resolve(response && response.data);
      }).catch((error) => {
        reject(error && error.response);
      });
  });
};

const patch = (url, data) => {
  return new Promise((resolve, reject) => {
    axios.patch(url, data)
      .then((response) => {
        resolve(response && response.data);
      }).catch((error) => {
        reject(error && error.response);
      });
  });
};

const deleteRequest = (url) => {
  return new Promise((resolve, reject) => {
    axios.delete(url)
      .then((response) => {
        resolve(response && response.data);
      }).catch((error) => {
        reject(error && error.response);
      });
  });
};

const serialPromises = function(promises) {
  const process = function(i,args) {
    const curr = promises[i]
    const next = function(res){ process(i+1,res) }
    if (curr) curr(args).then(next).catch(next)
  }
  process(0);
};

export default {
  get,
  post,
  put,
  patch,
  deleteRequest,
  serialPromises,
};
