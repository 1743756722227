import React, { Component } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import HttpRequest from '../../utils/httpRequest';
import ConfirmModal from '../../components/ConfirmModal';
import './ManageDiscounts.scss';

class ManageDiscounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discountModalIsOpen: false,
      confirmModalIsOpen: false,
      discounts: [],
      products: [],
      productId: null,
      isCreate: false,
      errorMessage: '',
      currentDiscount: null,
      onShelf: true,
    };
    this.closeModal = this.closeModal.bind(this);
    this.addDiscount = this.addDiscount.bind(this);
    this.saveDiscount = this.saveDiscount.bind(this);
    this.handleAfterOpen = this.handleAfterOpen.bind(this);
    this.confirmDeleteDiscount = this.confirmDeleteDiscount.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
  }
  componentDidMount() {
    this.updateState = (stateObj) => {
      this.setState(stateObj);
    };
    this.getDiscounts();
    this.getProducts();
  }
  componentWillUnmount() {
    this.updateState = () => { };
  }
  getDiscounts() {
    HttpRequest.get('/api/crm/discounts')
      .then(({ discounts }) => {
        this.updateState({ discounts });
      });
  }
  getProducts() {
    HttpRequest.get('/api/crm/products')
      .then(({ products }) => {
        this.updateState({ products });
      });
  }
  addDiscount() {
    this.setState({ discountModalIsOpen: true, isCreate: true, currentDiscount: null });
  }
  closeModal() {
    this.setState({ discountModalIsOpen: false, errorMessage: '' });
  }
  handleAfterOpen() {
    const { isCreate, currentDiscount } = this.state;
    if (isCreate) {
      this.refs.name.value = null;
      this.refs.amount.value = null;
      this.refs.timeCount.value = null;
      this.refs.discount.value = null;
      this.setState({ onShelf: true, productId: null });
    } else {
      this.refs.name.value = currentDiscount.name;
      this.refs.amount.value = currentDiscount.amount;
      this.setState({ onShelf: currentDiscount.onShelf, productId: currentDiscount.product.id });
      if (!currentDiscount.isInUse) {
        this.refs.timeCount.value = currentDiscount.timeCount;
        this.refs.discount.value = currentDiscount.discount;
      }
    }
  }
  createDiscount() {
    const name = this.refs.name.value;
    const amount = this.refs.amount.value;
    const timeCount = parseInt(this.refs.timeCount.value, 10);
    const discount = parseFloat(this.refs.discount.value);
    const { onShelf, productId } = this.state;
    if (!name || !amount || !timeCount || !discount || !productId) {
      this.setState({ errorMessage: 'Name, Product Label, Discount and Time Count Required' });
      return;
    }
    const discountObj = {
      name,
      productId,
      amount,
      timeCount,
      discount,
      onShelf,
    };
    HttpRequest.post('/api/crm/discounts', discountObj).then(() => {
      this.closeModal();
      this.getDiscounts();
    });
  }
  modifyDiscount() {
    const { currentDiscount, onShelf, productId } = this.state;
    const discount = {
      name: this.refs.name.value,
      productId,
      amount: this.refs.amount.value,
      onShelf,
    };
    discount.timeCount = parseInt(this.refs.timeCount.value, 10);
    discount.discount = parseFloat(this.refs.discount.value);
    if (!discount.name || !discount.amount) {
      this.setState({ errorMessage: 'Name, Label Required' });
      return;
    }
    if (discount.timeCount < 0 || discount.discount < 0) {
      this.setState({ errorMessage: 'Discount and Time Count Required' });
      return;
    }
    HttpRequest.patch(`/api/crm/discounts/${currentDiscount.id}`, discount).then(() => {
      this.closeModal();
      this.getDiscounts();
    });
  }
  saveDiscount(event) {
    event.preventDefault();
    const { isCreate } = this.state;
    if (isCreate) {
      this.createDiscount();
    } else {
      this.modifyDiscount();
    }
  }
  confirmDeleteDiscount() {
    const { currentDiscount } = this.state;
    HttpRequest.deleteRequest(`/api/crm/discounts/${currentDiscount.id}`)
      .then(() => {
        this.closeConfirmModal();
        this.getDiscounts();
      });
  }
  closeConfirmModal() {
    this.setState({ confirmModalIsOpen: false });
  }
  editDiscount(currentDiscount) {
    this.setState({ discountModalIsOpen: true, isCreate: false, currentDiscount });
  }
  deleteDiscount(currentDiscount) {
    this.setState({ currentDiscount, confirmModalIsOpen: true });
  }
  handleProductChange = (option) => {
    this.setState({ productId: option.value });
  };
  render() {
    const { discounts, products, productId, discountModalIsOpen, errorMessage, confirmModalIsOpen, onShelf } = this.state;
    const productOptions = products.map(item => ({ label: item.name, value: item.id }));
    return (
      <div className="manage-discounts">
        <div className="manage-header">
          <button className="btn btn-primary" onClick={this.addDiscount}>Add Discount</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Product Name</th>
              <th>Time Count</th>
              <th>Amount</th>
              <th>Discount</th>
              <th>On/Off Shelf</th>
              <th>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              discounts.map(discount => (
                <tr key={discount.id}>
                  <td>{discount.name}</td>
                  <td>{discount.product.name}</td>
                  <td>{discount.timeCount}</td>
                  <td>{discount.amount}</td>
                  <td>{discount.discount}</td>
                  <td>{discount.onShelf ? 'On' : 'Off'}</td>
                  <td>
                    <i className="operation-item far fa-edit" onClick={() => this.editDiscount(discount)}></i>
                    <i className="operation-item far fa-trash-alt" onClick={() => this.deleteDiscount(discount)}></i>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <Modal
          className="modal-container discount-modal-content"
          isOpen={discountModalIsOpen}
          contentLabel="PaymentTerm"
          ariaHideApp={false}
          onAfterOpen={this.handleAfterOpen}
          onRequestClose={this.closeModal}>
          <form className="discount-form modal">
            <div className="modal-header">
              <span>Discount</span>
              <i className="fas fa-times" onClick={this.closeModal}></i>
            </div>
            <div className="error-message text-center">{errorMessage}</div>
            <div className="input-group">
              <label>Name:</label>
              <span className="validate-indicator">*</span>
              <input ref="name" type="text" className="form-control" />
            </div>
            <div className="input-group">
              <label>Product:</label>
              <span className="validate-indicator">*</span>
              <Select
                className="select"
                value={productId}
                onChange={this.handleProductChange}
                options={productOptions} />
            </div>
            <div className="input-group">
              <label>Time Count:</label>
              <span className="validate-indicator">*</span>
              <input ref="timeCount" type="number" className="form-control" />
            </div>
            <div className="input-group">
              <label>Amount:</label>
              <span className="validate-indicator">*</span>
              <input ref="amount" type="text" className="form-control" />
            </div>
            <div className="input-group">
              <label>Discount:</label>
              <span className="validate-indicator">*</span>
              <input ref="discount" type="number" className="form-control" />
            </div>
            <div className="input-group">
              <label>On Shelf:</label>
              <span className="validate-indicator">*</span>
              <div className="onoffswitch">
                <input type="checkbox" name="onoffswitch"
                  className="onoffswitch-checkbox" id="onShelf"
                  checked={onShelf}
                  onChange={(event) => { this.setState({ onShelf: event.target.checked }); }} />
                <label className="onoffswitch-label" htmlFor="onShelf">
                  <span className="onoffswitch-inner">
                    <span className="label-left">On Shelf</span>
                    <span className="label-right">Off Shelf</span>
                  </span>
                  <span className="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={this.saveDiscount}>Save</button>
            </div>
          </form>
        </Modal>
        <ConfirmModal
          message="Are you sure to delete this payment term?"
          isOpen={confirmModalIsOpen}
          onConfirm={this.confirmDeleteDiscount}
          onCancel={this.closeConfirmModal} />
      </div>
    );
  }
}

export default ManageDiscounts;
