import React, { Component } from 'react';
import AuthUtils from '../../utils/auth';
import './SignIn.scss';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInStatus: 'init',
      message: '',
    };
    this.signIn = this.signIn.bind(this);
  }

  signIn(event) {
    event.preventDefault();
    const email = this.refs.email.value;
    if (!email) {
      this.setState({ message: 'Email Required' });
      return;
    }
    this.setState({ signInStatus: 'pending' });
    AuthUtils.signIn({ email })
      .then(() => {
        this.setState({ signInStatus: 'success', message: 'Please check your email.' });
      })
      .catch(() => {
        this.setState({ signInStatus: 'init', message: 'Sign in fail, please try again.' });
      });
  }
  render() {
    const { signInStatus, message } = this.state;
    return (
      <div className="sign-container">
        <div className="sign-content">
          <h1 className="sign-header text-center">Codasheet Store</h1>
          <form>
            <div className="error-message text-center">{ message }</div>
            {
              signInStatus !== 'success' && (
                <div>
                  <input ref="email" type="email" className="form-control" />
                  <button className="btn btn-primary signIn-btn" onClick={this.signIn} disabled={signInStatus === 'pending'}>OK</button>
                </div>
              )
            }
          </form>
        </div>
      </div>
    );
  }
}

export default SignIn;
