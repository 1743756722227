import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import AuthUtils from '../../utils/auth';
import CustomDropdown, { DropdownDirection } from '../../components/CustomDropdown';
import { AuthorizationActions } from '../../store/authorization';
import './UserMenu.scss';

class UserMenu extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    history: PropTypes.object.isRequired,
    signOut: PropTypes.func,
  }
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
  }

  signOut() {
    const { history, signOut } = this.props;
    AuthUtils.signOut()
      .then(() => {
        signOut();
        AuthUtils.clearCurrentUser();
        history.push('/');
      });
  }

  render() {
    const { currentUser } = this.props;
    return (
      <div className="user-menu">
        <CustomDropdown dropDirection={DropdownDirection.RightDown}
          dropdownTriggerRender={() => (
            <i className="fas fa-user-circle"></i>
          )}>
          <div className="dropdown-menu">
            <div className="dropdown-item-header">{currentUser.email}</div>
            <Link className="dropdown-item link" to="/profile">Profile</Link>
            <div className="dropdown-item" onClick={this.signOut}>Sign Out</div>
          </div>
        </CustomDropdown>
      </div>
    );
  }
}


const mapDispatchToProps = {
  signOut: AuthorizationActions.signOut,
};

export default withRouter(connect(null, mapDispatchToProps)(UserMenu));
