import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import QueryString from 'query-string';
import Modal from 'react-modal';
import _ from 'lodash';
import './Organizations.scss'
import httpRequest from '../../utils/httpRequest';
import OrganizationDetail from './OrganizationDetail';

const getExpiredDate = (organization) => {
  const teamEditionUsage = _.get(organization, 'teamEdition');
  if (!teamEditionUsage) {
    return 'Unlimited';
  }
  return new Date(new Date(teamEditionUsage.startTime).valueOf() + teamEditionUsage.duration).toLocaleDateString();
}

const getReadableFileSize = (sizeInBytes) => {
  if (!sizeInBytes) return `0B`;
  const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  return `${(sizeInBytes / (1024 ** i)).toFixed(2) * 1}${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

class Organizations extends Component {

  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      showNewOrganizationsDialog: false,
    }
    this.showOrganizationDetail = this.showOrganizationDetail.bind(this);
    this.closeOrganizationDetail = this.closeOrganizationDetail.bind(this);
    this.upgradeToEnterprisePlan = this.upgradeToEnterprisePlan.bind(this);
    this.createOrganizations = this.createOrganizations.bind(this);
  }

  componentWillMount() {
    this.loadOrganizations();
  }

  componentWillUnmount() {
    this.loading = false;
  }

  loadOrganizations(){
    this.loading = true;
    httpRequest.get('/api/crm/organizations')
      .then(({ organizations }) => {
        if (this.loading) {
          this.loading = false;
          this.setState({ organizations });
        }
      });
  }

  showOrganizationDetail(organizationID) {
    const { history, location } = this.props;
    history.push(`${location.pathname}?id=${organizationID}`);
  }

  closeOrganizationDetail() {
    const { history, location } = this.props;
    history.push(location.pathname);
  }

  upgradeToEnterprisePlan(activeOrganization) {
    httpRequest.post('/api/crm/usages', null, {
      params: { 
        suiteCode: 'enterprise-20users-1y',
        organizationId: activeOrganization.id,
      }
    });
    this.loadOrganizations();
  }

  createOrganizations() {
    if (!this.refs.organizationIds.value || !this.refs.suiteCode.value) {
      return;
    }
    const organzations = this.refs.organizationIds.value.replace(' ', '').split(',');
    const promises = _.map(organzations, (orgId) => {
      httpRequest.post('/api/crm/usages', null, {
        params: { suiteCode: this.refs.suiteCode.value, organizationId: orgId },
      });
    });
    promises.push(new Promise(() => {
      this.loadOrganizations();
    }));
    httpRequest.serialPromises(promises);
  }

  render() {
    const { organizations } = this.state;
    const { location } = this.props;
    const query = QueryString.parse(location.search);
    const activeOrganization = organizations.find(o => o.id === (query && query.id));
    return (
      <div className="organizations">
        <button onClick={() => {this.setState({showNewOrganizationsDialog: true})}}>Create New Organizations Usages</button>
        <div className="organizations-content">
          <table>
            <thead className="small">
              <tr>
                <th>Name</th>
                <th>ID</th>
                <th>User Usage</th>
                <th>Space Usage</th>
                <th>Email Usage</th>
                <th>Expired Date</th>
                <th>Created Time</th>
              </tr>
            </thead>
            <tbody className="small">
              {_.sortBy(organizations, org => new Date(org.createdTime)).map((organization) => (
                <tr key={organization.id}>
                  <td className="organization-name" onClick={() => this.showOrganizationDetail(organization.id)}>{organization.name}</td>
                  <td>{organization.id}</td>
                  <td>
                    <span>{_.get(organization, 'userUsage.used', 0)}</span>/<span>{_.get(organization, 'userUsage.total', 0)}</span>
                  </td>
                  <td>
                    <span>{getReadableFileSize(_.get(organization, 'spaceUsage.used', 0) * (1024 ** 3))}</span>/<span>{getReadableFileSize(_.get(organization, 'spaceUsage.total', 0) * (1024 ** 3))}</span>
                  </td>
                  <td>
                    <span>{_.get(organization, 'emailUsage.used', 0)}</span>/<span>{_.get(organization, 'emailUsage.total', 0)}</span>
                  </td>
                  <td>
                    {getExpiredDate(organization)}
                  </td>
                  <td>
                    {organization.createdTime && new Date(organization.createdTime).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {activeOrganization && (
          <Modal
            className="modal-container organization-detail-modal"
            isOpen={true}
            contentLabel="card"
            ariaHideApp={false}
            onRequestClose={this.closeOrganizationDetail}>
            <div className="organization-thumbnail">
              <div className="table">Organization Detail</div>
              <div className="organization-usage-table">
                <table>
                  <thead className="small">
                    <tr>
                      <th>Name</th>
                      <th>ID</th>
                      <th>User Usage</th>
                      <th>Space Usage</th>
                      <th>Email Usage</th>
                      <th>Expired Date</th>
                      <th>Created Time</th>
                    </tr>
                  </thead>
                  <tbody className="small">
                    <tr key={activeOrganization.id}>
                      <td>{activeOrganization.name}</td>
                      <td>{activeOrganization.id}</td>
                      <td>
                        <span>{_.get(activeOrganization, 'userUsage.used', 0)}</span>/<span>{_.get(activeOrganization, 'userUsage.total', 0)}</span>
                      </td>
                      <td>
                        <span>{getReadableFileSize(_.get(activeOrganization, 'spaceUsage.used', 0) * (1024 ** 3))}</span>/<span>{getReadableFileSize(_.get(activeOrganization, 'spaceUsage.total', 0) * (1024 ** 3))}</span>
                      </td>
                      <td>
                        <span>{_.get(activeOrganization, 'emailUsage.used', 0)}</span>/<span>{_.get(activeOrganization, 'emailUsage.total', 0)}</span>
                      </td>
                      <td>
                        {getExpiredDate(activeOrganization)}
                      </td>
                      <td>
                        {activeOrganization.createdTime && new Date(activeOrganization.createdTime).toLocaleString()}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {!activeOrganization.teamEdition && !activeOrganization.userUsage && !activeOrganization.spaceUsage && !activeOrganization.emailUsage &&
                  <button className="btn btn-primary" onClick={() => this.upgradeToEnterprisePlan(activeOrganization)}>升级至企业版</button>
                }
              </div>
            </div>
            <div className='modal-content'>
              <OrganizationDetail organizationId={query.id} />
            </div>
          </Modal>
        )}

        <Modal
          className="modal-container"
          isOpen={this.state.showNewOrganizationsDialog}
          contentLabel="card"
          ariaHideApp={false}
          onRequestClose={() => {this.setState({showNewOrganizationsDialog: false})}}>
          <div className='modal-content'>
            <label>OrganizationIds (split by comma)</label>
            <textarea className="form-control" type="text" ref="organizationIds" />
            <label>Suite Code</label>
            <input className="form-control" type="text" ref="suiteCode" />
            <button className="btn btn-primary" onClick={this.createOrganizations}>CreateOrganizations</button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default withRouter(Organizations)
