import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import HttpRequest from '../../utils/httpRequest';
import './Orders.scss';

const parseAmount = amount => parseFloat(amount.toFixed(2));

const getOrderTotalAmount = (order) => {
  let totalAmount = 0;
  order.orderDetailInfos.forEach((orderDetail) => {
    totalAmount += parseAmount(orderDetail.transactionAmount);
  });
  return totalAmount;
};

const getInlineVoucherUrl = voucher => `/api/crm/files/inline?filePath=${voucher}`;

class Orders extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      orders: [],
      currentOrder: null,
    };
    this.closeOrderVoucher = this.closeOrderVoucher.bind(this);
    this.changeOrderStatus = this.changeOrderStatus.bind(this);
  }
  componentDidMount() {
    this.updateState = (stateObj) => {
      this.setState(stateObj);
    };
    this.getOrders();
  }
  componentWillMount() {
    this.loading = true;
    HttpRequest.get('/api/crm/organizations')
      .then(({ organizations }) => {
        if (this.loading) {
          this.loading = false;
          this.setState({ organizations });
        }
      });
  }
  componentWillUnmount() {
    this.loading = false;
    this.updateState = () => { };
  }
  getOrders() {
    HttpRequest.get('/api/crm/orders')
      .then(({ orders }) => {
        this.updateState({ orders });
      });
  }
  closeOrderVoucher() {
    this.setState({ isOpenOrderDetail: false });
  }
  openOrderVoucher(order) {
    this.setState({ isOpenOrderDetail: true, currentOrder: order });
  }
  changeOrderStatus() {
    const { currentOrder } = this.state;
    HttpRequest.patch(`/api/crm/orders/${currentOrder.id}`)
      .then((order) => {
        this.getOrders();
        this.closeOrderVoucher();
      });
  }

  showOrderDetail(organizationID) {
    const { history } = this.props;
    history.push(`/organizations?id=${organizationID}`);
  }

  render() {
    const { orders, isOpenOrderDetail, currentOrder, organizations } = this.state;
    return (
      <div className="Orders">
        <div className="order-content">
          <table>
            <thead className="small">
              <tr>
                <th>Order ID</th>
                <th>Payment Method</th>
                <th>Total Amount</th>
                <th>Created At</th>
                <th>Created By</th>
                <th>Organization</th>
                <th>Status</th>
                <th>Operation</th>
              </tr>
            </thead>
            <tbody className="small">
              {orders.map(order => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>{order.paymentMethod}</td>
                  <td>{getOrderTotalAmount(order)}</td>
                  <td>{moment(new Date(order.createdAt)).format('YYYY-MM-DD hh:mm')}</td>
                  <td>{order.createdBy}</td>
                  <td className="organization-name" onClick={() => this.showOrderDetail(order.organizationId)}>{_.get(organizations.find(o => o.id === order.organizationId), 'name')}</td>
                  <td>{order.status}</td>
                  <td>
                    {order.transactionVoucher && <button className="btn btn-primary"
                      onClick={() => this.openOrderVoucher(order)}>view order</button>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {
          currentOrder && (
            <Modal
              className="modal-container order-voucher-modal"
              isOpen={isOpenOrderDetail}
              contentLabel="card"
              ariaHideApp={false}
              onRequestClose={this.closeOrderVoucher}>
              <div className="modal-header">
                Order Transaction Voucher
              </div>
              <div className="modal-content">
                <div className="voucher-item">
                  <span className="voucher-item-label">Order ID</span>
                  <span className="voucher-item-value">{currentOrder.id}</span>
                </div>
                <div className="voucher-item">
                  <span className="voucher-item-label">Total Amount</span>
                  <span className="voucher-item-value">{getOrderTotalAmount(currentOrder)}</span>
                </div>
                <div className="voucher-item">
                  <span className="voucher-item-label">Created At</span>
                  <span className="voucher-item-value">{moment(new Date(currentOrder.createdAt)).format('YYYY-MM-DD hh:mm')}</span>
                </div>
                <div className="voucher-item">
                  <span className="voucher-item-label">Payer Name</span>
                  <span className="voucher-item-value">{currentOrder.transactionVoucher.payerName}</span>
                </div>
                <div className="voucher-item">
                  <span className="voucher-item-label">Payer Phone</span>
                  <span className="voucher-item-value">{currentOrder.transactionVoucher.payerPhone}</span>
                </div>
                <div className="voucher-item">
                  <span className="voucher-item-label">Payer Account</span>
                  <span className="voucher-item-value">{currentOrder.transactionVoucher.payerAccount}</span>
                </div>
                <div className="voucher-item">
                  <span className="voucher-item-label">Payer Account Name</span>
                  <span className="voucher-item-value">{currentOrder.transactionVoucher.payerAccountName}</span>
                </div>
                <div className="voucher-item">
                  <span className="voucher-item-label">voucher</span>
                  <span className="voucher-item-value voucher-image"
                    style={{ backgroundImage: `url("${getInlineVoucherUrl(currentOrder.transactionVoucher.voucher)}")` }}></span>
                </div>
              </div>
              {currentOrder.status === 'unpaid' && <div className="modal-footer">
                <button className="btn btn-primary" onClick={() => this.changeOrderStatus(currentOrder)}>Change Status to paid</button>
              </div>}
            </Modal>
          )
        }
      </div>
    );
  }
}

export default withRouter(Orders);
