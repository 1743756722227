import React, { Component } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import HttpRequest from '../../utils/httpRequest';
import ConfirmModal from '../../components/ConfirmModal';
import './ManageProducts.scss';

const ProductCategory = {
  email: 'email',
  space: 'space',
  user: 'user',
  formTheme: 'formTheme',
  teamEdition: 'teamEdition',
  trialEdition: 'trialEdition',
};

const ProductCategoryOptions = Object.keys(ProductCategory).map(key => ({ label: key, value: ProductCategory[key] }));

class ManageProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenConfirmModal: false,
      modalIsOpen: false,
      products: [],
      isCreate: false,
      category: null,
      errorMessage: '',
      currentProduct: null,
      onShelf: true,
    };
    this.closeModal = this.closeModal.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.saveProduct = this.saveProduct.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleAfterOpen = this.handleAfterOpen.bind(this);
    this.confirmDeleteProduct = this.confirmDeleteProduct.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
  }
  componentDidMount() {
    this.updateState = (stateObj) => {
      this.setState(stateObj);
    };
    this.getProducts();
  }
  componentWillUnmount() {
    this.updateState = () => { };
  }
  getProducts() {
    HttpRequest.get('/api/crm/products')
      .then(({ products }) => {
        this.updateState({ products });
      });
  }
  addProduct() {
    this.setState({ modalIsOpen: true, isCreate: true });
  }
  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  handleAfterOpen() {
    const { isCreate, currentProduct } = this.state;
    if (isCreate) {
      this.refs.name.value = null;
      this.refs.description.value = null;
      this.refs.unitPrice.value = null;
      this.refs.cardinalNumber.value = null;
      this.refs.itemCode.value = null;
      this.setState({ category: null, onShelf: true });
    } else {
      this.refs.name.value = currentProduct.name;
      this.setState({ onShelf: currentProduct.onShelf });
      this.refs.description.value = currentProduct.description;
      this.refs.unitPrice.value = currentProduct.unitPrice;
      this.refs.cardinalNumber.value = currentProduct.cardinalNumber;
      this.refs.itemCode.value = currentProduct.itemCode;
      this.setState({ category: currentProduct.category });
    }
  }
  createProduct() {
    const name = this.refs.name.value;
    const description = this.refs.description.value;
    const unitPrice = parseFloat(this.refs.unitPrice.value, 10);
    const cardinalNumber = parseInt(this.refs.cardinalNumber.value, 10);
    const itemCode = this.refs.itemCode.value;
    const category = this.state.category;
    const onShelf = this.state.onShelf;
    if (!name || !(unitPrice >= 0) || !(cardinalNumber > 0 || !itemCode)) {
      this.setState({ errorMessage: 'Name, Price, ItemCode and CardinalNumber Required' });
      return;
    }
    const product = {
      name,
      description,
      unitPrice,
      cardinalNumber,
      itemCode,
      category,
      onShelf,
    };
    HttpRequest.post('/api/crm/products', product).then(() => {
      this.closeModal();
      this.getProducts();
    });
  }
  modifyProduct() {
    const { currentProduct, onShelf } = this.state;
    const product = {
      name: this.refs.name.value,
      onShelf,
    };
    product.description = this.refs.description.value;
    product.itemCode = this.refs.itemCode.value;
    product.unitPrice = parseFloat(this.refs.unitPrice.value, 10);
    product.cardinalNumber = parseInt(this.refs.cardinalNumber.value, 10);
    product.category = this.state.category;
    if (!product.name) {
      this.setState({ errorMessage: 'Name Required' });
      return;
    }
    if (product.unitPrice < 0 || product.cardinalNumber < 0 || !product.itemCode) {
      this.setState({ errorMessage: 'Price, ItemCode and CardinalNumber Required' });
      return;
    }
    HttpRequest.patch(`/api/crm/products/${currentProduct.id}`, product).then(() => {
      this.closeModal();
      this.getProducts();
    });
  }
  saveProduct(event) {
    event.preventDefault();
    const { isCreate } = this.state;
    if (isCreate) {
      this.createProduct();
    } else {
      this.modifyProduct();
    }
  }
  confirmDeleteProduct() {
    const { currentProduct } = this.state;
    HttpRequest.deleteRequest(`/api/crm/products/${currentProduct.id}`)
      .then(() => {
        this.closeConfirmModal();
        this.getProducts();
      });
  }
  closeConfirmModal() {
    this.setState({ isOpenConfirmModal: false, errorMessage: '' });
  }
  editProduct(currentProduct) {
    this.setState({ modalIsOpen: true, isCreate: false, currentProduct });
  }
  deleteProduct(currentProduct) {
    this.setState({ currentProduct, isOpenConfirmModal: true });
  }
  handleCategoryChange(option) {
    this.setState({ category: option.value });
  }
  render() {
    const { products, modalIsOpen, errorMessage,
      isOpenConfirmModal, category, onShelf } = this.state;
    return (
      <div className="manage-products">
        <div className="manage-header">
          <button className="btn btn-primary" onClick={this.addProduct}>Add Product</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Item Code</th>
              <th>Unit Price</th>
              <th>Cardinal Number</th>
              <th>Description</th>
              <th>On/Off Shelf</th>
              <th>Operation</th>
            </tr>
          </thead>
          <tbody>
            {
              products.map(product => (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>{product.category}</td>
                  <td>{product.itemCode}</td>
                  <td>{product.unitPrice}</td>
                  <td>{product.cardinalNumber}</td>
                  <td className="text-overflow" title={product.description}>{product.description}</td>
                  <td>{product.onShelf ? 'On' : 'Off'}</td>
                  <td>
                    <i className="operation-item far fa-edit" onClick={() => this.editProduct(product)}></i>
                    <i className="operation-item far fa-trash-alt" onClick={() => this.deleteProduct(product)}></i>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <Modal
          className="modal-container product-modal-content"
          isOpen={modalIsOpen}
          contentLabel="Plan"
          ariaHideApp={false}
          onAfterOpen={this.handleAfterOpen}
          onRequestClose={this.closeModal}>
          <form className="product-form modal">
            <div className="modal-header">
              <span>Product</span>
              <i className="fas fa-times" onClick={this.closeModal}></i>
            </div>
            <div className="error-message text-center">{errorMessage}</div>
            <div className="input-group">
              <label>Name:</label>
              <span className="validate-indicator">*</span>
              <input ref="name" type="text" className="form-control" />
            </div>
            <div>
              <div className="input-group">
                <label>Description:</label>
                <span className="validate-indicator"></span>
                <textarea ref="description" className="form-control"></textarea>
              </div>
              <div className="input-group">
                <label>Category:</label>
                <span className="validate-indicator"></span>
                <Select
                  className="select"
                  value={category}
                  onChange={this.handleCategoryChange}
                  options={ProductCategoryOptions} />
              </div>
              <div className="input-group">
                <label>Item Code:</label>
                <span className="validate-indicator">*</span>
                <input ref="itemCode" type="text" className="form-control" />
              </div>
              <div className="input-group">
                <label>Unit Price:</label>
                <span className="validate-indicator">*</span>
                <input ref="unitPrice" type="number" className="form-control" />
              </div>
              <div className="input-group">
                <label>Cardinal Number:</label>
                <span className="validate-indicator">*</span>
                <input ref="cardinalNumber" type="number" className="form-control" />
              </div>
              <div className="input-group">
                <label>On Shelf:</label>
                <span className="validate-indicator">*</span>
                <div className="onoffswitch">
                  <input type="checkbox" name="onoffswitch"
                    className="onoffswitch-checkbox" id="onShelf"
                    checked={onShelf}
                    onChange={(event) => { this.setState({ onShelf: event.target.checked }); }} />
                  <label className="onoffswitch-label" htmlFor="onShelf">
                    <span className="onoffswitch-inner">
                      <span className="label-left">On Shelf</span>
                      <span className="label-right">Off Shelf</span>
                    </span>
                    <span className="onoffswitch-switch"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={this.saveProduct}>Save</button>
            </div>
          </form>
        </Modal>
        <ConfirmModal
          message="Are you sure to delete this product?"
          isOpen={isOpenConfirmModal}
          onConfirm={this.confirmDeleteProduct}
          onCancel={this.closeConfirmModal} />
      </div>
    );
  }
}

export default ManageProducts;
