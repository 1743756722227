import HttpRequest from './httpRequest';

const signOut = () => HttpRequest.get('/api/crm/auth/signout');

const signIn = data => HttpRequest.post('/api/crm/auth/signin', data);

const signInWithToken = tokenId => HttpRequest.get(`/api/crm/auth/signin/${tokenId}`);

const saveCurrentUser = (user) => {
  localStorage.setItem('currentUser', JSON.stringify(user));
};

const clearCurrentUser = () => {
  localStorage.removeItem('currentUser');
};

const getCurrentUser = () => JSON.parse(localStorage.getItem('currentUser'));

export default {
  signOut,
  signIn,
  signInWithToken,
  saveCurrentUser,
  clearCurrentUser,
  getCurrentUser,
};
