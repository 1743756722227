import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import App from './App';
import SignIn from './user/SignIn';
import SignInWithToken from './user/SignInWithToken';

const Root = () => (
  <div className="container">
    <Router>
      <Switch>
        <Route exact path="/signIn" component={SignIn} />
        <Route exact path="/signInWithToken/:tokenId" component={SignInWithToken} />
        <Route path="/" component={App} />
      </Switch>
    </Router>
    <ReduxToastr
      timeOut={3000}
      newestOnTop={true}
      position="top-center" />
  </div>
);

export default Root;
