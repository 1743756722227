import axios from 'axios';
import _ from 'lodash';

const setInterceptors = (history) => {
  // Add a request interceptor
  axios.interceptors.request.use((config) => {
    // Do something before request is sent
    return config;
  }, (error) => {
    // Do something with request error
    return Promise.reject(error);
  });

  // Add a response interceptor
  axios.interceptors.response.use((response) => {
    // Do something with response data
    return response;
  }, (error) => {
    if (_.result(error, 'response.status') === 401) {
      history.replace('/signIn');
    }
    return Promise.reject(error);
  });
};

export default setInterceptors;
