import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './UserProfile.scss';

class UserProfile extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
  };
  render() {
    return (
      <div className="user-profile">
        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.authorization.currentUser,
});

export default connect(mapStateToProps)(UserProfile);
